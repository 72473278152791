import HomePage from "./components/main";
import homeLinks from "./server/links";
import homeLoader from "./server/loader";
import platformMeta from "./server/meta";

export type { IndexLoader, Filters } from "./types";

export const links = homeLinks;
export const loader = homeLoader;
export const meta = platformMeta;
export default HomePage;
