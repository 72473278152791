import type { IndexLoader } from "~/routes/$platform._index/types";

import { useLoaderData, useParams } from "@remix-run/react";
import { de } from "date-fns/locale";
import { useEffect } from "react";
import { Container, Flex, Heading, Text } from "sparta";
import { PlatformClass } from "~/sdk/qs1/Platform";
import PostExporter from "~/utils/export/PostExporter";
import { useStateBanner } from "~/utils/hooks/StateBanner/useStateBanner";
import AddUsers from "./AddUsers";
import Explore from "./Explore";
import FeaturedHero from "./FeaturedHero";

export default function IndexPage() {
  const loaderData = useLoaderData<IndexLoader>() as IndexLoader;
  useStateBanner(loaderData);
  const params = useParams();
  const platform = PlatformClass.toPlatform(params.platform);
  const subHeadingTextMap = {
    fedciv: "Strategic intelligence on tech innovations in federal civilian contracts.",
    defense: "Decisive intelligence for enhancing Defense & Intelligence contract strategies.",
    health: "Actionable health insights from federal healthcare intel.",
  };
  const subHeadingText =
    subHeadingTextMap[platform.toLowerCase()] ||
    "Decisive intelligence for enhancing Defense & Intelligence contract strategies.";

  useEffect(() => {
    const { url } = loaderData;

    const postExporter = new PostExporter(url);
    postExporter?.exportIfApplicable();
  }, [loaderData]);

  return (
    <Container p="2">
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="0">
          <Heading as="h1" size="7">
            {platform}
          </Heading>
          <Text>{subHeadingText}</Text>
        </Flex>
        <AddUsers loaderData={loaderData} />
        <FeaturedHero />
        <Explore />
      </Flex>
    </Container>
  );
}
