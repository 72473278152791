import type { LinksFunction } from "@remix-run/node";
import paginationStyle from "~/style/components/Pagination.css?url";
import homeStyle from "../home.css?url";

const links: LinksFunction = () => [
  { rel: "stylesheet", href: homeStyle },
  { rel: "stylesheet", href: paginationStyle },
];

export default links;
